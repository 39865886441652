import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Link, useHistory } from "react-router-dom";
import { useDashboard } from "../contexts/DashboardContext";
import "../App.css";

const AddLicenseType = () => {
  let history = useHistory();
  let token = Cookies.get("token");
  const { setAuthenticated } = useDashboard();
  let [credentials, setCredentials] = useState({
    name: "",
    description: "",
  });
  let [error, setError] = useState("");

  const handleChange = (e) => {
    var letterNumber = /^[a-z|A-Z|]+[a-z|A-Z|0-9]*/;
    if (e.target.value.match(letterNumber) || e.target.value === "") {
      setCredentials({ ...credentials, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, description } = credentials;
    axios
      .post(
        "/api/License",
        {
          name,
          description,
        },
        {
          headers: {
            "x-auth-token": token,
          },
        }
      )
      .then(() => {
        setCredentials({
          name: "",
          description: "",
        });
        history.push("/dashboard");
      })
      .catch((err) => {
        setError(err.response.data.message);
        setTimeout(() => {
          setError("");
        }, 1000);
      });
  };

  useEffect(() => {
    if (token) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
      history.push("/");
    }
  }, []);

  return (
    <>
      <div className="container">
        <div className="addcustomer">
          <h2>Add License Type</h2>

          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Name"
              name="name"
              id="name"
              value={credentials.name}
              onChange={handleChange}
              required
            ></input>
            <br />
            <input
              type="text"
              placeholder="Description"
              name="description"
              id="description"
              value={credentials.description}
              onChange={handleChange}
              required
            ></input>
            {error}
            <br />
            <br />
            <Link to="/dashboard">
              <button
                type="button"
                className="btn"
                style={{ float: "left", background: "grey", border: "grey" }}
              >
                Cancel
              </button>
            </Link>
            <button type="submit" className="btn" style={{ float: "right" }}>
              Add License Type
            </button>
            <br />
          </form>
        </div>
      </div>
    </>
  );
};

export default AddLicenseType;
