import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { CSVLink } from "react-csv";
import { useDashboard } from "../contexts/DashboardContext";
import "../App.css";

const AlertComponent = ({ token }) => {
  const {
    selectedCustomerId,
    selectedSystemId,
    loading,
    setLoading,
    selectedValue,
    alertInfo,
    setAlertInfo,
    systemInfo,
    selectedType,
    filteredAlerts,
    setFilteredAlerts,
    admin,
  } = useDashboard();

  let [alertDate, setAlertDate] = useState({
    startDate: "",
    endDate: "",
  });
  let [error, setError] = useState("");
  let [data, setData] = useState("");

  const handleChange = (e) => {
    e.preventDefault();
    setAlertDate({ ...alertDate, [e.target.name]: e.target.value });
  };

  const headers = [
    { label: "Alert", key: "alert" },
    { label: "Local timestamp", key: "local_timestamp" },
    { label: "Machine id", key: "machineid" },
    { label: "Remote timestamp", key: "remotetimestamp" },
    { label: "System id", key: "systemid" },
  ];

  const downloadAlerts = () => {
    let start, end;
    if (alertDate.startDate === "" || alertDate.endDate === "") {
      setError(
        <span style={{ color: "#ff4f6e" }}>Enter the start and end dates.</span>
      );
    } else {
      start = new Date(alertDate.startDate).toISOString().split("T")[0];
      end = new Date(alertDate.endDate).toISOString().split("T")[0];
      if (start > end) {
        setError(
          <span style={{ color: "#ff4f6e" }}>
            The start date should be earlier than the end date.
          </span>
        );
      } else {
        let alertData = alertInfo.filter((alert) => {
          let time = new Date(alert.local_timestamp)
            .toISOString()
            .split("T")[0];
          return time >= start && time <= end;
        });
        if (alertData.length === 0) {
          setError(
            <span style={{ color: "#ff4f6e" }}>
              For the specified time period, no alerts were found!
            </span>
          );
        } else {
          let alerts = alertData.map((alert) => ({
            alert: alert.alert,
            local_timestamp: new Date(alert.local_timestamp).toLocaleString(),
            machineid: alert.machineid,
            remotetimestamp: new Date(alert.remotetimestamp).toLocaleString(),
            systemid: alert.systemid,
          }));
          setData(alerts);
          setError(
            <span style={{ color: "#22ae54" }}>
              Alerts generated. Now, download the file.
            </span>
          );
        }
      }
    }
  };

  useEffect(() => {
    if (selectedCustomerId.length > 0) {
      axios
        .get(`/api/alert/${selectedCustomerId}`, {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => {
          if (res.data.data) {
            let sortedAlerts = [...res.data.data].sort(
              (a, b) =>
                new Date(b.local_timestamp) - new Date(a.local_timestamp)
            );
            setAlertInfo(sortedAlerts);
            setLoading(false);
          } else {
            setAlertInfo([]);
            setLoading(false);
          }
        })
        .catch((err) => console.log(err));
    } else {
      setAlertInfo([]);
    }
  }, [selectedCustomerId]);

  useEffect(() => {
    if (selectedSystemId === "") {
      setFilteredAlerts(alertInfo);
    } else {
      let array = [...alertInfo].filter(
        (info) => info.systemid === selectedSystemId
      );
      setFilteredAlerts(array);
    }
  }, [selectedSystemId, alertInfo]);

  useEffect(() => {
    setLoading(true);
  }, [selectedValue]);

  useEffect(() => {
    let array = [...systemInfo].filter(
      (info) => info.licensename === selectedType
    );
    let ids = array.map((info) => info.systemid);
    if (selectedType !== "") {
      let final = alertInfo.filter((info) => ids.includes(info.systemid));
      setFilteredAlerts(final);
    } else {
      setFilteredAlerts(alertInfo);
    }
  }, [selectedType]);

  let popupel = useRef(null);

  function popup() {
    popupel.current.style.display = "block";
  }

  function popupclose() {
    popupel.current.style.display = "none";
    setAlertDate({
      startDate: "",
      endDate: "",
    });
    setError("");
    setData([]);
  }

  return (
    <>
      <div className="box">
        <div className="innerbox">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2>Alerts</h2>
            {alertInfo.length > 0 && (selectedValue.length > 0 || !admin) ? (
              <button
                type="button"
                className="btnmini"
                style={{ margin: "1rem" }}
                onClick={() => popup()}
              >
                Download alerts
              </button>
            ) : null}
          </div>
          {!loading ? (
            alertInfo.length > 0 && filteredAlerts.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <th>Alert</th>
                    <th>System Id</th>
                    <th>Local Timestamp</th>
                    <th>Remote Timestamp</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAlerts.map((info, key) => {
                    return (
                      <tr key={key}>
                        <td>{info.alert}</td>
                        <td>{info.systemid}</td>
                        <td>
                          {new Date(info.local_timestamp)
                            .toLocaleString()
                            .replace(",", " ")}
                        </td>
                        <td>
                          {new Date(info.remotetimestamp)
                            .toLocaleString()
                            .replace(",", " ")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : selectedValue.length > 0 || !admin ? (
              "No Alert Information is available."
            ) : (
              "Select a customer first."
            )
          ) : (
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          )}
        </div>
      </div>
      <div ref={popupel} className="popup">
        <div className="innerpopup">
          <div className="pophead">
            Download alerts
            <button type="button" className="icon" onClick={popupclose}>
              &#xF00D;
            </button>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <label style={{ margin: "0.75rem 1rem" }}>From</label>
            <input
              style={{ margin: "0.5rem 1rem" }}
              type="date"
              name="startDate"
              min={
                alertInfo.length > 0
                  ? new Date(alertInfo[alertInfo.length - 1].local_timestamp)
                      .toISOString()
                      .split("T")[0]
                  : "2021-08-25"
              }
              max={new Date().toISOString().split("T")[0]}
              value={alertDate.startDate}
              onChange={handleChange}
              required
            ></input>
            <label style={{ margin: "0.75rem 1rem" }}>to</label>
            <input
              style={{ margin: "0.5rem 1rem" }}
              type="date"
              name="endDate"
              min={alertDate.startDate}
              max={new Date().toISOString().split("T")[0]}
              value={alertDate.endDate}
              onChange={handleChange}
              disabled={alertDate.startDate.length === 0}
              required
            ></input>
          </div>
          <div style={{ textAlign: "center", margin: "1rem" }}>
            <span>{error}</span>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "1rem 0 0.5rem 0",
            }}
          >
            {data.length === 0 && (
              <button
                type="submit"
                style={{
                  backgroundColor: "grey",
                  color: "white",
                  padding: "1rem",
                  border: "none",
                }}
                onClick={downloadAlerts}
              >
                Generate alerts
              </button>
            )}
            {data.length > 0 && (
              <CSVLink
                style={{
                  backgroundColor: "#22aa53",
                  color: "white",
                  padding: "1rem",
                }}
                onClick={() => popupclose()}
                data={data}
                headers={headers}
                filename={`${new Date().toISOString().split("T")[0]}.csv`}
              >
                Download csv file
              </CSVLink>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AlertComponent;
