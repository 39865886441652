import React from "react";
import "../App.css";

const Footer = () => {
  return (
    <div className="footer">
      <small>
        &copy; 2021 &nbsp;
        <a
          href="https://www.securweave.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          SecurWeave
        </a>
        , All rights reserved
      </small>
    </div>
  );
};

export default Footer;
