import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Link, useHistory } from "react-router-dom";
import { useDashboard } from "../contexts/DashboardContext";
import "../App.css";

const SelectLicense = () => {
  let history = useHistory();
  let token = Cookies.get("gtoken");
  const [type, setType] = useState("");
  const { licenses, setLicenses } = useDashboard();

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("/api/customer/googlelogin", {
        token,
        type,
      })
      .then((res) => {
        Cookies.set("token", res.data.token);
        Cookies.set("role", res.data.role);
        Cookies.set("customerid", res.data.customerid);
        Cookies.remove("gtoken");
        history.push("/dashboard");
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!token) {
      history.push("/");
    } else {
      axios
        .get("/api/License/getlicenselist")
        .then((res) => {
          setLicenses(res.data.data);
        })
        .catch(() => {
          history.push("/");
        });
    }
  }, []);

  const handleType = (e) => {
    e.preventDefault();
    setType(e.target.value);
  };

  return (
    <>
      <div className="container">
        <div className="selectlicense">
          <h2 style={{ color: "rgb(95 193 94)" }}>
            Select Your Preferred OS For License Below
          </h2>
          <form onSubmit={handleSubmit}>
            <select
              className="license"
              value={type}
              onChange={handleType}
              required
            >
              <option value="">Select OS</option>
              {licenses.map((license, i) => {
                return (
                  <option key={i} value={license.type}>
                    {license.name}
                  </option>
                );
              })}
            </select>
            <br />
            <br />
            <Link to="/">
              <button
                type="button"
                className="btn"
                style={{ float: "left", background: "grey", border: "grey" }}
              >
                Cancel
              </button>
            </Link>
            <button type="submit" className="btn" style={{ float: "right" }}>
              Continue
            </button>
            <br />
          </form>
        </div>
      </div>
    </>
  );
};

export default SelectLicense;
