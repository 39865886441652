import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { useDashboard } from "../contexts/DashboardContext";
import "../App.css";

const CustomerVerification = (props) => {
  let history = useHistory();
  const [type, setType] = useState("");
  const { match } = props;
  let { verificationcode } = match.params;
  const [verifed, setVerified] = useState(false);
  const [error, setError] = useState("");
  const { licenses, setLicenses } = useDashboard();

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("/api/customer/customerverification", {
        verificationcode,
        type,
      })
      .then(() => {
        setVerified(true);
        setType("");
      })
      .catch(() => {
        setError("Invalid verification link or account is already verified");
        setTimeout(() => {
          setError("");
        }, 1000);
      });
  };

  useEffect(() => {
    axios
      .get("/api/License/getlicenselist")
      .then((res) => {
        setLicenses(res.data.data);
      })
      .catch(() => {
        history.push("/");
      });
  }, []);

  const handleType = (e) => {
    e.preventDefault();
    setType(e.target.value);
  };

  return (
    <>
      <div className="container">
        <div className="selectlicense">
          {verifed ? (
            <p>
              Congratulations 🎉 Your account is now verified. Go to &nbsp;
              <a href="/" style={{ color: "#11ae54", fontWeight: "bold" }}>
                Login Page
              </a>
            </p>
          ) : (
            <>
              <h2 style={{ color: "rgb(95 193 94)" }}>
                Select your preferred OS for license and Verify your account
              </h2>
              <form onSubmit={handleSubmit}>
                <select
                  className="license"
                  value={type}
                  onChange={handleType}
                  required
                >
                  <option value="">Select OS</option>
                  {licenses.map((license, i) => {
                    return (
                      <option key={i} value={license.type}>
                        {license.name}
                      </option>
                    );
                  })}
                </select>

                <br />
                <br />
                <Link to="/">
                  <button
                    type="button"
                    className="btn"
                    style={{
                      float: "left",
                      background: "grey",
                      border: "grey",
                    }}
                  >
                    Cancel
                  </button>
                </Link>
                <button
                  type="submit"
                  className="btn"
                  style={{ float: "right" }}
                >
                  Verify your account
                </button>

                <span
                  style={{
                    color: "#96add7",
                    fontWeight: "bold",
                  }}
                >
                  {error}
                </span>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CustomerVerification;
