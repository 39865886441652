import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import AddCustomer from "./components/AddCustomer";
import AddLicense from "./components/AddLicense";
import AddLicenseType from "./components/AddLicenseType";
import SelectLicense from "./components/SelectLicense";
import CustomerVerification from "./components/CustomerVerification";
import { DashboardProvider } from "./contexts/DashboardContext";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "./App.css";

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <DashboardProvider>
        <div className="App">
          <Router>
            <Navbar />
            <Switch>
              <Route path="/" exact component={Login} />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/addcustomer" component={AddCustomer} />
              <Route path="/addlicense" component={AddLicense} />
              <Route path="/addlicensetype" component={AddLicenseType} />
              <Route path="/selectlicense" component={SelectLicense} />
              <Route
                path="/customerverification/:verificationcode"
                component={CustomerVerification}
              />
            </Switch>
            <Footer />
          </Router>
        </div>
      </DashboardProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
