import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import CustomerInfoComponent from "./CustomerInfo";
import SystemInfoComponent from "./SystemInfo";
import AlertComponent from "./Alert";
import { useDashboard } from "../contexts/DashboardContext";
import IdleTimerContainer from "./IdleTimerContainer";
import "../App.css";

const Dashboard = () => {
  const {
    selectedValue,
    setSelectedValue,
    setSelectedCustomerId,
    setLoading,
    customers,
    setCustomers,
    setAuthenticated,
    admin,
    setAdmin,
    selectedCustomerId,
  } = useDashboard();
  let history = useHistory();
  let token = Cookies.get("token");
  let role = Cookies.get("role");
  let customerid = Cookies.get("customerid");
  let name = Cookies.get("name");

  useEffect(() => {
    if (token && role) {
      if (role === "1") {
        setAdmin(true);
      } else {
        setTimeout(() => setSelectedCustomerId(customerid), 10);
      }
    }
  }, []);

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
  };

  useEffect(() => {
    if (!token) {
      history.push("/");
    } else {
      axios
        .get("/api/customer", {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => {
          setCustomers(res.data.customers);
          setLoading(false);
          setAuthenticated(true);
          if (!admin) {
            Cookies.set("name", res.data.customers[0].name);
          }
        })
        .catch(() => {
          history.push("/");
          setAuthenticated(false);
        });
    }
  }, []);

  useEffect(() => {
    if (selectedValue.length > 0) {
      let selectedCustomer = customers.find(
        (customer) => customer.username === selectedValue
      );
      setSelectedCustomerId(selectedCustomer.value);
    } else {
      setSelectedCustomerId("");
    }
  }, [selectedValue]);

  return (
    <>
      <div className="container">
        <IdleTimerContainer></IdleTimerContainer>
        <br />
        <br />
        {admin ? (
          <select value={selectedValue} onChange={handleChange}>
            <option value="">Select Customer</option>
            {customers.map((customer, i) => {
              return (
                <option key={i} value={customer.username}>
                  {customer.label} ({customer.username})
                </option>
              );
            })}
          </select>
        ) : (
          name && (
            <h1>
              Welcome, <span style={{ color: "rgb(95 193 94)" }}>{name}</span>
            </h1>
          )
        )}

        <AlertComponent token={token} />
        <SystemInfoComponent token={token} />
        <CustomerInfoComponent token={token} />
      </div>
    </>
  );
};

export default Dashboard;
