import React, { useEffect } from "react";
import axios from "axios";
import { useDashboard } from "../contexts/DashboardContext";
import "../App.css";

const CustomerInfoComponent = ({ token }) => {
  const {
    selectedCustomerId,
    setSelectedType,
    selectedValue,
    customerIndex,
    setCustomerIndex,
    loading,
    setLoading,
    customerInfo,
    setCustomerInfo,
    admin,
  } = useDashboard();

  useEffect(() => {
    if (selectedCustomerId.length > 0) {
      axios
        .get(`/api/customer/${selectedCustomerId}`, {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => {
          setCustomerInfo(res.data.customers);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    } else {
      setCustomerInfo([]);
      setLoading(false);
    }
  }, [selectedCustomerId]);

  const handleCustomer = (type, i) => {
    setSelectedType((license) => (license === type ? "" : type));
    if (customerIndex === i) {
      setCustomerIndex(-1);
    } else {
      setCustomerIndex(i);
    }
  };

  useEffect(() => {
    setCustomerIndex(-1);
    setSelectedType("");
  }, [selectedValue]);

  useEffect(() => {
    setLoading(true);
  }, [selectedValue]);

  return (
    <div className="box">
      <div className="innerbox">
        <h2>License Information</h2>
        {!loading ? (
          customerInfo.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>License Type</th>
                  <th>Total No. Of License</th>
                  <th>Available License</th>
                  <th>Expiry Date</th>
                </tr>
              </thead>
              <tbody>
                {customerInfo.map((info, i) => {
                  return (
                    <tr
                      key={i}
                      onClick={() => handleCustomer(info.name, i)}
                      style={{
                        background: customerIndex === i ? "#313b58" : "inherit",
                      }}
                    >
                      <td>{info.name}</td>
                      <td>{info.count}</td>
                      <td>{info.available}</td>
                      <td>
                        {new Date(info.expiry)
                          .toLocaleString()
                          .replace(",", " ")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : selectedValue.length > 0 || !admin ? (
            "No Customer Information is available."
          ) : (
            "Select a customer first."
          )
        ) : (
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerInfoComponent;
