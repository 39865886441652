import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import { useDashboard } from "../contexts/DashboardContext";
import "../App.css";

const SystemInfoComponent = ({ token }) => {
  const {
    selectedCustomerId,
    selectedType,
    setSelectedSystemId,
    selectedValue,
    systemIndex,
    setSystemIndex,
    customerIndex,
    loading,
    setLoading,
    systemInfo,
    setSystemInfo,
    systemDetails,
    setSystemDetails,
    filteredAlerts,
    admin,
  } = useDashboard();

  let [filtered, setFiltered] = useState([]);

  const onDelete = (systemId, customerId, machineId) => {
    axios
      .post(
        "/api/system/disable",
        {
          systemId,
          customerId,
          machineId,
        },
        {
          headers: {
            "x-auth-token": token,
          },
        }
      )
      .then(() => {
        let newArray = filtered.filter(
          (system) => system.systemid !== systemId
        );
        setSystemInfo(newArray);
        popupclose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (selectedCustomerId.length > 0) {
      axios
        .get(`/api/system/${selectedCustomerId}`, {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => {
          let array = res.data.systems.map(({ ...system }) => {
            let currentTime = new Date();
            let lastActiveTime = new Date(system.lastactive);
            let difference = currentTime - lastActiveTime;
            system.minutesSinceLastActive = Math.floor(difference / 60000);
            return system;
          });
          setSystemInfo(array);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    } else {
      setSystemInfo([]);
      setLoading(false);
    }
  }, [selectedCustomerId]);

  useEffect(() => {
    if (selectedType === "") {
      setFiltered(systemInfo);
    } else {
      let array = [...systemInfo].filter(
        (info) => info.licensename === selectedType
      );
      setFiltered(array);
    }
  }, [selectedType, systemInfo]);

  const handleSystem = (id, i) => {
    if (selectedType.length > 0 && filteredAlerts.length === 0) {
      setSelectedSystemId("");
      if (systemIndex === i) {
        setSystemIndex(-1);
      } else {
        setSystemIndex(i);
      }
    } else {
      setSelectedSystemId((systemid) => (systemid === id ? "" : id));
      if (systemIndex === i) {
        setSystemIndex(-1);
      } else {
        setSystemIndex(i);
      }
    }
  };

  useEffect(() => {
    setSystemIndex(-1);
    setSelectedSystemId("");
  }, [selectedValue]);

  useEffect(() => {
    setSystemIndex(-1);
  }, [customerIndex]);

  useEffect(() => {
    setLoading(true);
  }, [selectedValue]);

  let popupel = useRef(null);
  function popup(info) {
    popupel.current.style.display = "block";
    setSystemDetails(info);
  }
  function popupclose() {
    popupel.current.style.display = "none";
  }

  return (
    <>
      <div className="box">
        <div className="innerbox">
          <h2>System Information</h2>
          {!loading ? (
            systemInfo.length > 0 && filtered.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <th>Distribution</th>
                    <th>Location</th>
                    <th>System Id</th>
                    <th>Last Active</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {filtered.map((info, i) => {
                    return (
                      <tr
                        key={i}
                        style={{
                          background: systemIndex === i ? "#313b58" : "inherit",
                        }}
                      >
                        <td onClick={() => handleSystem(info.systemid, i)}>
                          {info.licensename === null ? "—" : info.licensename}
                        </td>
                        <td>{info.location === null ? "—" : info.location}</td>
                        <td>{info.systemid === null ? "—" : info.systemid}</td>
                        <td>
                          {info.lastactive === null ? (
                            "—"
                          ) : info.minutesSinceLastActive >= 10 ? (
                            <>
                              {new Date(info.lastactive)
                                .toLocaleString()
                                .replace(",", " ")}
                              <span
                                className="dot"
                                style={{ backgroundColor: "red" }}
                              ></span>
                            </>
                          ) : (
                            <>
                              {new Date(info.lastactive)
                                .toLocaleString()
                                .replace(",", " ")}
                              <span
                                className="dot"
                                style={{ backgroundColor: "#11ae54" }}
                              ></span>
                            </>
                          )}
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btnmini"
                            onClick={() => popup(info)}
                          >
                            Get System Details
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : selectedValue.length > 0 || !admin ? (
              "No System Information is available."
            ) : (
              "Select a customer first."
            )
          ) : (
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          )}
        </div>
      </div>

      <div ref={popupel} className="popup">
        <div className="innerpopup">
          <div className="pophead">
            System Details
            <button type="button" className="icon" onClick={popupclose}>
              &#xF00D;
            </button>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              className="deletebutton"
              onClick={() =>
                onDelete(
                  systemDetails.systemid,
                  systemDetails.customerid,
                  systemDetails.machineid
                )
              }
            >
              Delete System
            </button>
          </div>
          <table>
            <thead>
              <tr>
                <th>Type</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Machine Id</td>
                <td>
                  {systemDetails.machineid === null
                    ? "—"
                    : systemDetails.machineid}
                </td>
              </tr>
              <tr>
                <td>Features</td>
                <td>
                  {systemDetails.feature === null ? "—" : systemDetails.feature}
                </td>
              </tr>
              <tr>
                <td>Architecture</td>
                <td>
                  {systemDetails.architecture === null
                    ? "—"
                    : systemDetails.architecture}
                </td>
              </tr>
              <tr>
                <td>CPU-op-mode(s)</td>
                <td>
                  {systemDetails.cpuopmodes === null
                    ? "—"
                    : systemDetails.cpuopmodes}
                </td>
              </tr>
              <tr>
                <td>Byte Order</td>
                <td>
                  {systemDetails.byteorder === null
                    ? "—"
                    : systemDetails.byteorder}
                </td>
              </tr>
              <tr>
                <td>CPU(s)</td>
                <td>
                  {systemDetails.cpucount === null
                    ? "—"
                    : systemDetails.cpucount}
                </td>
              </tr>
              <tr>
                <td>Distribution</td>
                <td>
                  {systemDetails.licensename === null
                    ? "—"
                    : systemDetails.licensename}
                </td>
              </tr>
              <tr>
                <td>Release</td>
                <td>
                  {systemDetails.release === null ? "—" : systemDetails.release}
                </td>
              </tr>
              <tr>
                <td>Codename</td>
                <td>
                  {systemDetails.codename === null
                    ? "—"
                    : systemDetails.codename}
                </td>
              </tr>
              <tr>
                <td>Kernel</td>
                <td>
                  {systemDetails.kernel === null ? "—" : systemDetails.kernel}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SystemInfoComponent;
