import React from "react";
import { Link, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import securweave from "../Images/securweave logo png file (8).png";
import { useDashboard } from "../contexts/DashboardContext";
import NodeRSA from "node-rsa";

const Navbar = () => {
  let location = useLocation();
  const {
    authenticated,
    setAuthenticated,
    setSelectedValue,
    setSelectedCustomerId,
    setSelectedSystemId,
    admin,
    setAdmin,
  } = useDashboard();
  let token = Cookies.get("token");
  let customerid = Cookies.get("customerid");

  const data = `CHESS Version 1.0
https://chess-ams.securweave.com/api/
${customerid}
${token}
`;

  let keyPrivate = new NodeRSA(process.env.REACT_APP_PRIVATE_KEY);

  keyPrivate.setOptions({ encryptionScheme: "pkcs1" });

  let encryptedData = keyPrivate.encryptPrivate(data);

  const downloadFile = () => {
    const element = document.createElement("a");
    const file = new Blob([encryptedData], {
      type: "text/plain;charset=utf-8",
    });
    element.href = URL.createObjectURL(file);
    element.download = "chess.lic";
    document.body.appendChild(element);
    element.click();
  };
  return (
    <>
      <div className="header">
        <div className="logo">
          <img alt="SecurWeave logo" src={securweave} />
        </div>
        <div className="header_links">
          {authenticated && location.pathname === "/dashboard" && (
            <>
              <>
                {!admin && location.pathname === "/dashboard" && (
                  <Link to="#" className="link" onClick={downloadFile}>
                    Download your key
                  </Link>
                )}
              </>
              {location.pathname === "/dashboard" && admin && (
                <>
                  <Link to="/addlicensetype" className="link bb">
                    Add License Type
                  </Link>
                  <div className="link drop customerinfo">
                    <small>Customer Information</small>
                    <div className="mbox customerinfocontent">
                      <Link to="/addcustomer" className="bb">
                        <small className="icon">&#10010;</small> &nbsp; Add
                        Customer
                      </Link>
                      <Link to="/addlicense">
                        <small className="icon">&#x1F511;</small> &nbsp; Add
                        License
                      </Link>
                    </div>
                  </div>
                </>
              )}
              <Link
                className="link"
                to="/"
                onClick={() => {
                  Cookies.remove("token");
                  Cookies.remove("role");
                  Cookies.remove("customerid");
                  Cookies.remove("username");
                  Cookies.remove("gtoken");
                  setAuthenticated(false);
                  setSelectedValue("");
                  setSelectedCustomerId("");
                  setSelectedSystemId("");
                  setAdmin(false);
                }}
              >
                <small className="icon">&#xF08B;</small> &nbsp; Logout
              </Link>
            </>
          )}
          <div className="link drop">
            <small className="icon">&#xF142;</small>
            <div className="mbox">
              <a href="#contact" className="bb">
                <small className="icon">&#xF0E0;</small> &nbsp; Contact Us
              </a>
              <a href="#help">
                <small className="icon">&#xF059;</small> &nbsp; Help
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
