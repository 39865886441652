import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Securweave from "../Images/securweave logo png file (8).png";
import Cookies from "js-cookie";
import { useDashboard } from "../contexts/DashboardContext";
import { GoogleLogin } from "@react-oauth/google";

import "../App.css";

const Login = () => {
  let history = useHistory();
  const {
    setAuthenticated,
    setSelectedValue,
    setSelectedCustomerId,
    setSelectedSystemId,
    setAdmin,
  } = useDashboard();
  let [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  let [error, setError] = useState("");
  let [login, setLogin] = useState(true);
  let [registered, setRegistered] = useState(false);
  let [customerData, setCustomerData] = useState({
    name: "",
    username: "",
    password: "",
    email: "",
  });

  useEffect(() => {
    Cookies.remove("token");
    Cookies.remove("role");
    Cookies.remove("customerid");
    Cookies.remove("name");
    Cookies.remove("gtoken");
    setAuthenticated(false);
    setSelectedValue("");
    setSelectedCustomerId("");
    setSelectedSystemId("");
    setAdmin(false);
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    const { username, password } = credentials;
    axios
      .post("/api/token", {
        username,
        password,
      })
      .then((res) => {
        if (res.data.message) {
          setError(res.data.message);
          setTimeout(() => {
            setError("");
          }, 1000);
        } else {
          Cookies.set("token", res.data.token);
          Cookies.set("role", res.data.role);
          Cookies.set("customerid", res.data.customerid);
          setCredentials({
            username: "",
            password: "",
          });
          history.push("/dashboard");
        }
      })
      .catch(() => {
        setError("Invalid Credentials");
        setTimeout(() => {
          setError("");
        }, 1000);
      });
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    const { name, username, password, email } = customerData;
    axios
      .post("/api/Customer/signup", {
        name,
        username,
        password,
        email,
      })
      .then((res) => {
        if (res.data.message) {
          setError(res.data.message);
          setTimeout(() => {
            setError("");
          }, 1000);
        } else {
          setCredentials({
            name: "",
            username: "",
            password: "",
            email: "",
          });
          setRegistered(true);
        }
      })
      .catch((err) => {
        setError(err.response.data.message);
        setTimeout(() => {
          setError("");
        }, 1000);
      });
  };

  const handleLoginChange = (e) => {
    e.preventDefault();
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSignUpChange = (e) => {
    e.preventDefault();
    setCustomerData({ ...customerData, [e.target.name]: e.target.value });
  };

  const divstyle = {
    height: "100px",
    width: "100px",
    marginLeft: "10px",
  };

  const imgstyle = {
    height: "100px",
    width: "100px",
    margin: "5px 0",
  };

  const successResponseGoogle = (credentialResponse) => {
    let token = credentialResponse.credential;

    axios
      .post("/api/customer/alreadyexists", {
        token,
      })
      .then((res) => {
        if (!res.data) {
          Cookies.set("gtoken", token);
          history.push("/selectlicense");
        } else {
          axios
            .post("/api/customer/googlelogin", { token })
            .then((res) => {
              Cookies.set("token", res.data.token);
              Cookies.set("role", res.data.role);
              Cookies.set("customerid", res.data.customerid);
              setCredentials({
                username: "",
                password: "",
              });
              history.push("/dashboard");
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  const failureResponseGoogle = (response) => {
    console.log(response);
  };

  return (
    <>
      <div className="container">
        <br />
        <br />
        <div className="half hide">
          <br />
          <br />
          <div style={divstyle}>
            <img src={Securweave} style={imgstyle} alt="SecurWeave logo"></img>
          </div>
          <h1>CHESS Alert Management System</h1>
          <br />
          <br />
        </div>

        <div className="login">
          <div
            style={{
              fontSize: "1.5rem",
              marginBottom: "1rem",
              fontWeight: "bold",
            }}
          >
            <a
              onClick={() => setLogin(true)}
              style={{
                color: login ? "#11ae54" : "inherit",
                paddingRight: "1rem",
                cursor: "pointer",
              }}
            >
              Sign In
            </a>
            <span style={{ color: "grey" }}>|</span>
            <a
              onClick={() => setLogin(false)}
              style={{
                color: login ? "inherit" : "#11ae54",
                paddingLeft: "1rem",
                cursor: "pointer",
              }}
            >
              Sign Up
            </a>
          </div>

          {login ? (
            <>
              <form onSubmit={handleLogin}>
                <input
                  type="text"
                  placeholder="Username"
                  name="username"
                  id="username"
                  value={credentials.username}
                  onChange={handleLoginChange}
                  required
                ></input>
                <br />
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  id="password"
                  value={credentials.password}
                  onChange={handleLoginChange}
                  required
                ></input>

                {error}
                <br />
                <br />
                <button
                  type="submit"
                  className="btn"
                  style={{ float: "right" }}
                >
                  <small className="icon">&#xF2F6;</small> &nbsp; Sign In
                </button>
                <br />
              </form>
              <br />

              {/* <div className="googlelogin">
                <p>OR</p>
                <GoogleLogin
                  onSuccess={successResponseGoogle}
                  onError={failureResponseGoogle}
                  size="medium"
                  theme="filled_blue"
                  text="signin_with"
                  shape="pill"
                  width="200"
                />
              </div> */}
            </>
          ) : (
            <>
              {registered ? (
                <p
                  style={{
                    color: "#96add7",
                    fontWeight: "bold",
                    fontSize: "x-large",
                    lineHeight: "initial",
                  }}
                >
                  Please check your email to verify the account and select your
                  preferred license.
                </p>
              ) : (
                <form onSubmit={handleSignUp}>
                  <input
                    type="text"
                    placeholder="Your Name"
                    name="name"
                    id="name"
                    pattern="^[a-zA-Z]+.*$"
                    value={customerData.name}
                    onChange={handleSignUpChange}
                    required
                  ></input>
                  <br />
                  <input
                    type="text"
                    placeholder="Your Username"
                    name="username"
                    id="username"
                    pattern="^[a-z|A-Z|]+[a-z|A-Z|0-9]*"
                    value={customerData.username}
                    onChange={handleSignUpChange}
                    required
                  ></input>
                  <br />
                  <input
                    type="password"
                    placeholder="Your Password"
                    name="password"
                    id="password"
                    value={customerData.password}
                    onChange={handleSignUpChange}
                    required
                  ></input>
                  <br />
                  <input
                    type="email"
                    placeholder="Your Email"
                    name="email"
                    id="email"
                    value={customerData.email}
                    onChange={handleSignUpChange}
                    required
                  ></input>
                  {error}
                  <br />
                  <br />
                  <button
                    type="submit"
                    className="btn"
                    style={{ float: "right" }}
                  >
                    <small className="icon">&#xF2F6;</small> &nbsp; Sign Up
                  </button>
                  <br />
                </form>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Login;
