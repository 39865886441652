import { useState, createContext, useContext } from "react";
export const DashboardContext = createContext();
export const useDashboard = () => useContext(DashboardContext);

export const DashboardProvider = (props) => {
  let [authenticated, setAuthenticated] = useState(false);
  let [customers, setCustomers] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedSystemId, setSelectedSystemId] = useState("");
  const [customerIndex, setCustomerIndex] = useState(-1);
  const [systemIndex, setSystemIndex] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [alertInfo, setAlertInfo] = useState([]);
  let [systemInfo, setSystemInfo] = useState([]);
  let [systemDetails, setSystemDetails] = useState({});
  let [customerInfo, setCustomerInfo] = useState([]);
  let [admin, setAdmin] = useState(false);
  let [filteredAlerts, setFilteredAlerts] = useState([]);
  const [licenses, setLicenses] = useState([]);

  return (
    <DashboardContext.Provider
      value={{
        selectedValue,
        setSelectedValue,
        selectedCustomerId,
        setSelectedCustomerId,
        selectedType,
        setSelectedType,
        selectedSystemId,
        setSelectedSystemId,
        customerIndex,
        setCustomerIndex,
        systemIndex,
        setSystemIndex,
        loading,
        setLoading,
        alertInfo,
        setAlertInfo,
        systemInfo,
        setSystemInfo,
        systemDetails,
        setSystemDetails,
        customerInfo,
        setCustomerInfo,
        customers,
        setCustomers,
        authenticated,
        setAuthenticated,
        admin,
        setAdmin,
        filteredAlerts,
        setFilteredAlerts,
        licenses,
        setLicenses,
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};
