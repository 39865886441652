import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Link, useHistory } from "react-router-dom";
import { useDashboard } from "../contexts/DashboardContext";
import "../App.css";

const AddCustomer = () => {
  let history = useHistory();
  let token = Cookies.get("token");
  const { setAuthenticated } = useDashboard();
  let [credentials, setCredentials] = useState({
    name: "",
    expiry: "",
    username: "",
    password: "",
    email: "",
  });
  let [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, expiry, username, password, email } = credentials;
    axios
      .post(
        "/api/Customer",
        {
          name,
          expiry,
          username,
          password,
          email,
        },
        {
          headers: {
            "x-auth-token": token,
          },
        }
      )
      .then((res) => {
        if (res.data.message !== "Successfully Created") {
          setError(res.data.message);
          setTimeout(() => {
            setError("");
          }, 1000);
        } else {
          setCredentials({
            name: "",
            expiry: "",
            username: "",
            password: "",
            email: "",
          });
          history.push("/dashboard");
        }
      })
      .catch((err) => {
        setError(err.response.data.message);
        setTimeout(() => {
          setError("");
        }, 1000);
      });
  };
  const handleChange = (e) => {
    e.preventDefault();
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (token) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
      history.push("/");
    }
  }, []);

  return (
    <>
      <div className="container">
        <div className="addcustomer">
          <h2>Add Customer</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Type customer name here | Should start with a letter, Ex: John doe"
              name="name"
              id="name"
              pattern="^[a-zA-Z]+.*$"
              value={credentials.name}
              onChange={handleChange}
              required
            ></input>
            <br />
            <input
              type="text"
              placeholder="Type expiry date here"
              name="expiry"
              id="expiry"
              onFocus={(e) => {
                e.currentTarget.type = "date";
                e.currentTarget.min = new Date().toISOString().split("T")[0];
                e.currentTarget.max = "2050-01-01";
              }}
              value={credentials.expiry}
              onChange={handleChange}
              required
            ></input>
            <br />
            <input
              type="text"
              placeholder="Type customer username here | Should start with a letter, Ex: johndoe"
              name="username"
              id="username"
              pattern="^[a-z|A-Z|]+[a-z|A-Z|0-9]*"
              value={credentials.username}
              onChange={handleChange}
              required
            ></input>
            <br />
            <input
              type="password"
              placeholder="Type customer password here"
              name="password"
              id="password"
              value={credentials.password}
              onChange={handleChange}
              required
            ></input>
            <br />
            <input
              type="email"
              placeholder="Type customer email here"
              name="email"
              id="email"
              value={credentials.email}
              onChange={handleChange}
              required
            ></input>
            {error}
            <br />
            <br />
            <Link to="/dashboard">
              <button
                type="button"
                className="btn"
                style={{ float: "left", background: "grey", border: "grey" }}
              >
                Cancel
              </button>
            </Link>
            <button type="submit" className="btn" style={{ float: "right" }}>
              Add Customer
            </button>
            <br />
          </form>
        </div>
      </div>
    </>
  );
};

export default AddCustomer;
