import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Link, useHistory } from "react-router-dom";
import { useDashboard } from "../contexts/DashboardContext";
import "../App.css";

const AddLicense = () => {
  let history = useHistory();
  let token = Cookies.get("token");
  const [id, setId] = useState("");
  const [type, setType] = useState("");
  const { customers, setCustomers, licenses, setLicenses } = useDashboard();
  let [credentials, setCredentials] = useState({
    type: "",
    count: "",
    customerid: "",
    expiry: "",
  });
  let [error, setError] = useState("");

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { count, expiry } = credentials;
    axios
      .post(
        "/api/Customer/licenses",
        {
          type,
          count,
          customerid: id,
          expiry,
        },
        {
          headers: {
            "x-auth-token": token,
          },
        }
      )
      .then((res) => {
        setCredentials({
          type: "",
          count: "",
          customerid: "",
          expiry: "",
        });
        history.push("/dashboard");
      })
      .catch(() => {
        setError("Invalid Data");
        setTimeout(() => {
          setError("");
        }, 1000);
      });
  };
  useEffect(() => {
    if (!token) {
      history.push("/");
    } else {
      axios
        .get("/api/customer", {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => {
          setCustomers(res.data.customers);
        })
        .catch(() => {
          history.push("/");
        });
    }
  }, []);

  useEffect(() => {
    if (!token) {
      history.push("/");
    } else {
      axios
        .get("/api/License", {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => {
          setLicenses(res.data.data);
        })
        .catch(() => {
          history.push("/dashboard");
        });
    }
  }, []);

  const handleSystemId = (e) => {
    e.preventDefault();
    setId(e.target.value);
  };

  const handleType = (e) => {
    e.preventDefault();
    setType(e.target.value);
  };

  return (
    <>
      <div className="container">
        <div className="addcustomer">
          <h2>Add License</h2>

          <form onSubmit={handleSubmit}>
            <select className="license" value={type} onChange={handleType}>
              <option value="">Select License</option>
              {licenses.map((license, i) => {
                return (
                  <option key={i} value={license.type}>
                    {license.name}
                  </option>
                );
              })}
            </select>
            <br />
            <input
              type="number"
              placeholder="Count"
              name="count"
              id="count"
              min="1"
              value={credentials.count}
              onChange={handleChange}
              required
            ></input>
            <br />
            <select className="license" value={id} onChange={handleSystemId}>
              <option value="">Select Customer</option>
              {customers.map((customer, i) => {
                return (
                  <option key={i} value={customer.value}>
                    {customer.label}
                  </option>
                );
              })}
            </select>
            <br />
            <input
              type="text"
              placeholder="Expiry date"
              name="expiry"
              id="expiry"
              onFocus={(e) => {
                e.currentTarget.type = "date";
                e.currentTarget.min = new Date().toISOString().split("T")[0];
              }}
              value={credentials.expiry}
              onChange={handleChange}
              required
            ></input>
            {error}
            <br />
            <br />
            <Link to="/dashboard">
              <button
                type="button"
                className="btn"
                style={{ float: "left", background: "grey", border: "grey" }}
              >
                Cancel
              </button>
            </Link>
            <button type="submit" className="btn" style={{ float: "right" }}>
              Add License
            </button>
            <br />
          </form>
        </div>
      </div>
    </>
  );
};

export default AddLicense;
